<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="4" lg="4" md="4" sm="12">
        <v-text-field
          label="Title"
          readonly
          v-model="image.title"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="7" lg="7" md="7" sm="12">
        <v-text-field
          label="Description"
          readonly
          v-model="image.description"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="1" lg="1" md="1" sm="12">
        <v-btn outlined class="mr-4" @click="onBack">
          <v-icon>mdi-backspace</v-icon>Back</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <div v-if="type == 'image/jpeg'">
        <span
          style="padding-right: 5px; padding-top: 5px; display: inline-block"
        >
          <img
            class="detail-image"
            :src="`data:image/jpeg;base64,` + image.data"
          />
        </span>
      </div>
      <div v-if="type == 'image/png'">
        <span
          style="padding-right: 5px; padding-top: 5px; display: inline-block"
        >
          <img
            class="detail-image"
            :src="`data:image/jpeg;base64,` + image.data"
          />
        </span>
      </div>
      <div v-if="type == 'video/mp4'">
        <video id="video"></video>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import router from "@/router";
import store from "@/store";

export default {
  components: {},

  created() {
    this.get();
  },

  data: () => ({
    image: {},
    type: "video/mp4",
  }),

  methods: {
    get() {
      const url = "image/" + this.$route.params.id;
      apiService.get(url, this.getSuccess, this.getError);
    },

    getError(error) {
      const notification = {
        title: "Error getting the image",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    getSuccess(result) {
      this.type = result.data.type;
      if (result.data.type == "video/mp4") {
        var fileUrl = URL.createObjectURL(new Blob([result.data.data]));
        var video = document.getElementById("video");
        video.src = {
          type: 'video/mp4', src: fileUrl
        };
      }
      this.image = result.data;
    },

    onBack() {
      router.go(-1);
    },
  },
};
</script>

<style>
.detail-image {
  max-height: 100%;
  max-width: 100%;
}
</style>