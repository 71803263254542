<template>
  <v-container>
    <v-form v-model="valid">
      <h2>{{ title }}</h2>
      <v-row>
        <v-col cols="12" xl="4" lg="4" md="4" sm="12">
          <v-text-field
            label="Title"
            required
            v-model="image.title"
            :rules="rulesTitle"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xl="8" lg="8" md="8" sm="12">
          <v-text-field
            label="Description"
            required
            v-model="image.description"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="image.id == 0">
        <v-col cols="12">
          <v-file-input
            accept="image/png, image/jpeg, image/bmp, image/gif, video/mp4"
            counter
            label="Image"
            loading="isLoading"
            prepend-icon="mdi-image"
            outlined
            :show-size="1000"
            @change="onChanged"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip v-if="index < 2" label small>
                {{ text }}
              </v-chip>
              <span
                v-else-if="index === 2"
                class="overline grey--text text--darken-3 mx-2"
              >
                +{{ files.length - 2 }} File(s)
              </span>
            </template>
          </v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn outlined class="button" @click="onCancel">
          <v-icon left>mdi-cancel</v-icon>Cancel
        </v-btn>
        <v-btn
          outlined
          class="button"
          :disabled="!valid"
          @click="onSave"
        >
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-row>
      <div>
        <v-row>
          <v-col cols="12" lx="3" lg="3" md="3" sm="7">
            <v-text-field
              v-model="fileName"
              readonly
              label="Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lx="2" lg="2" md="2" sm="5">
            <v-text-field
              v-model="fileType"
              readonly
              label="Type"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lx="2" lg="2" md="2" sm="5">
            <v-text-field
              type="number"
              align="right"
              v-model="fileSize"
              readonly
              label="Size"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lx="5" lg="5" md="5" sm="7">
            <v-text-field
              v-model="fileDate"
              readonly
              label="Last Modified Date"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import ApiService from "@/_services/api-service"
import router from "@/router"
import store from "@/store"

export default {
  created() {
    this.get();
  },

  data: () => ({
    files: new FormData(),
    fileName: "",
    fileDate: "",
    fileType: "",
    fileSize: "",
    image: {
      id: 0,
      data: [],
      description: "",
      filename: "",
      lastModified: "",
      size: 0,
      title: "",
      type: "",
    },
    fileTypes: ["image/png", "image/jpeg", "image/bmp", "image/gif", "video/mp4"],
    rulesTitle: [
      (v) => !!v || "Title is required",
      (v) => (v || "").length <= 255 || "Title must be 255 characters or less",
    ],
    title: "Add Image",
    valid: false,
  }),

  methods: {
    get() {
      if (this.$route.params.id > 0) {
        const url = "image/" + this.$route.params.id;
        ApiService.get(url, this.getSuccess, this.getError);
      }
    },

    getError(error) {
      const notification = {
        title: "Error getting the image",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    getSuccess(response) {
      this.title = "Edit Thumbnail";
      this.image = response.data;
    },

    onChanged(image) {
      if (image) {
        const index = this.fileTypes.find((f) => f == image.type);
        if (index) {
          this.files = new FormData();
          this.files.append("file", image, image.name);
          this.fileName = image.name;
          this.fileType = image.type;
          this.fileDate = image.lastModifiedDate;
          this.fileSize = image.size;
        } else {
          const notification = {
            title: "Add Thumbnail",
            status: "error",
            messages: ["Unsupported file type " + image.type],
          };
          store.commit("setNotification", notification);
        }
      }
    },

    onCancel() {
      store.commit("setAddElementDisplay", false);
      router.go(-1);
    },

    onSave() {
      let url = "image";
      if (this.image.id == 0) {
        this.files.append("title", this.image.title);
        this.files.append("description", this.image.description);
        this.files.append("lastModified", this.image.lastModified);
        ApiService.post(url, this.files, this.saveSuccess, this.saveError);
      } else {
        url = url + "/" + this.image.id;
        ApiService.put(url, this.image, this.saveSuccess, this.saveError);
      }
    },

    saveSuccess() {
      const notification = {
        title: "Save Image",
        status: "success",
        messages: [this.image.title + " saved"],
      };
      store.commit("setNotification", notification);
      router.go(-1);
    },

    saveError(error) {
      const notification = {
        title: "Save Image",
        error: error,
      };
      store.commit("setResponseError", notification);
    },
  },

  router,

  watch: {},
};
</script>

<style lang="scss" scoped>
.button {
  margin-right: 20px;
}
</style>